.App {
  text-align: center;
}

@font-face {
  font-family: ToyotaType-Regular;
  src: url(../../Assets/Font/ToyotaType-Regular.otf);
}

@font-face {
  font-family: ToyotaType-Light;
  src: url(../../Assets/Font/ToyotaType-Light.otf);
}

@font-face {
  font-family: ToyotaType-Bold;
  src: url(../../Assets/Font/ToyotaType-Semibold.otf);
}

@font-face {
  font-family: LexusType-Regular;
  src: url(../../Assets/Font/FontLexus/NOR____.ttf);
}

@font-face {
  font-family: LexusType-Light;
  src: url(../../Assets/Font/FontLexus/NOBLI__.ttf);
}

@font-face {
  font-family: LexusType-Bold;
  src: url(../../Assets/Font/FontLexus/NOBD___.ttf);
}

.remove-empty-content:empty {
  display: none;
}
